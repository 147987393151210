<template>
  <LxpMobileHeader v-if="isMobile" title="연수 ・ 외부용 비밀번호변경">
    <template v-slot:left>
      <div class="util util-back">
        <router-link  :to="{name: 'Signin'}" class="util-actions util-actions-back">
          <i class="icon-history-back"></i>
        </router-link>
      </div>
    </template>
  </LxpMobileHeader>
   <!-- begin::kb-main -->
    <main class="kb-main" :id="isMobile ? 'kb-login-auth' : 'kb-login-sub'">
      <!-- main-header -->
      <div v-if="!isMobile" class="main-header">
        <!-- main-header > header-top -->
        <div class="header-top main-component">
          <div class="header-column">
            <router-link :to="{name: 'Signin'}" class="page-nav">
              <i class="icon-kb-nav-arrow"></i>
              <span class="text">이전</span>
            </router-link>
          </div>
        </div>
        <!-- main-header > header-title -->
        <div class="header-title">
          <h2 class="title">연수 ・ 외부용 비밀번호변경</h2>
        </div>
      </div>
      <!-- //main-header -->
      <!-- main-content -->
      <div class="main-content main-component">
        <div class="login-view-container">
          <div class="view-contents">
            <section class="content-section section-forms">
              <header class="section-header">
                <h4 class="title">개인정보 확인</h4>
              </header>
              <!-- kb-form-fields -->
              <div :class="isMobile ? 'section-content' : 'kb-form-fields'">

                <span v-if="isAuthentication" class="kb-form-label-text" style="font-size: 1.2em; margin-right: 1em;">{{ userInfo.lrnerNm }} / {{ userInfo.deptNm }} ( {{ userInfo.lrnerId}} )</span>


                <template v-else>

                  <!-- kb-form-row:직원번호 -->
                  <div :class="isMobile ? 'content-item' : 'kb-form-row'">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">직원번호</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                        <input v-model="info.lrnerId" type="text" style="width:80%" class="kb-form-control" placeholder="직원번호를 입력하세요" @keyup.enter="enterBirth">
                    </div>
                  </div>


                  <!-- kb-form-row:생년월일 -->
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">생년월일</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                        <input v-model="info.brdt" type="password" style="width:80%" class="kb-form-control" ref="birthDt" placeholder="생년월일을 입력하세요(6자리)" maxlength="6" @keyup.enter="clickIdentification">
                    </div>
                  </div>

                </template>



              </div>
              <!-- //kb-form-fields -->
              <div class="kb-form-buttons">
                <button v-if="!isAuthentication" class="kb-btn kb-btn-primary kb-btn-lg" @click="clickIdentification"><span class="text">본인확인</span></button>
              </div>
            </section>

            <Authentication
                v-if="isAuthentication && !certSuccess"
                :user-info="userInfo"
                :is-user-info="false"
                v-model:cert-success="certSuccess"
            />

            <section v-if="certSuccess" class="content-section section-forms section-divider">
              <header class="section-header">
                <h4 class="title">비밀번호 변경</h4>
              </header>
                <PasswordForm :lrner-id="userInfo.lrnerId" :change-effect="changeEffect" page-type="reissue" />
              <div class="kb-form-buttons">
                <button class="kb-btn kb-btn-primary kb-btn-lg" @click="changePassword"><span class="text">변경확인</span></button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </main>
</template>

<script>
import {computed, reactive, ref} from 'vue';
import {useAlert} from '@/assets/js/modules/common/alert';
import {useStore} from 'vuex';
import {getItem, lengthCheck, setParams} from '@/assets/js/util';
import {ACT_GET_AUTH_BY_BRDT} from '@/store/modules/auth/auth';
import Authentication from "@/components/auth/Authentication";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import navigationUtils from "@/assets/js/navigationUtils";
import PasswordForm from "@/components/my/PasswordForm";

export default {
  name: 'PasswordReissue',
  components: {PasswordForm, LxpMobileHeader, Authentication},
  setup(){

    const store = useStore();

    const {showMessage} = useAlert();

    const isMobile = navigationUtils.any();

    const mainId = computed(() => isMobile ?  'kb-login-auth ' : 'kb-login-sub');

    const info = reactive({
      lrnerId: '',
      brdt: '',
      reqtype: ''
    });

    const userInfo = reactive({
      lrnerNm : '',
      lrnerId : '',
      deptNm: '',
      brdt : '',
      mblTelno : '',
      emlAddr : '',
    });

    const password = ref('');
    const checkPassword = ref('');

    const isAuthentication = ref(false);
    const certSuccess = ref(false);


    const clickIdentification = () => {
      let result = true;

      if(!info.lrnerId){
        showMessage({
          title: '본인확인',
          text: '직원번호를 입력해주세요.'
        });
        result = false;
      } else if(info.brdt.length < 6) {
        showMessage({
          title: '본인확인',
          text: '생년월일은 6자리 숫자로 입력하셔야 합니다.'
        });
        result = false;
      }

      if(result) {
        store.dispatch(`auth/${ACT_GET_AUTH_BY_BRDT}`, info).then(res => {
          if(lengthCheck(res)) {
            setParams(userInfo,  getItem(res));
            isAuthentication.value = true;
          } else {
            showMessage({
              title: '본인확인',
              text: '입력하신 정보가 일치하지 않습니다.<br /> 다시 확인 후 입력해주세요.'
            });

          }
        }).catch(e => {
          console.error(e);
        })
      }
    };

    const changeEffect = ref(0);

    const changePassword = () => {
      changeEffect.value++;
      // if(validatePassword(password.value, checkPassword.value)) {
      //   store.dispatch(`auth/${ACT_UPDATE_AUTH_PWD}`, {
      //     lrnerId: userInfo.lrnerId,
      //     params: {
      //       password: password.value
      //     }
      //   }).then(res => {
      //     if(isSuccess(res)) {
      //       showMessage({
      //         text: '비밀번호가 변경되었습니다. 잘 기억하여 두시기 바랍니다.',
      //         callback: () => {
      //           router.push({name: 'Signin'});
      //         }
      //       });
      //     } else if(res.result.number === 301) {
      //       showMessage({
      //         text: res.result.message
      //       });
      //     }
      //   })
      // }
    }

    const goReissue = () => {
      info.lrnerId = '';
      info.brdt = '';

      isAuthentication.value = false;
    }


    return {
      info,
      userInfo,
      isAuthentication,
      password,
      checkPassword,

      certSuccess,
      isMobile,
      mainId,
      changeEffect,

      changePassword,
      clickIdentification,
      goReissue,

    }
  }
  , methods: {
    enterBirth: function(){
       this.$refs.birthDt.focus();
    }
  }
};
</script>