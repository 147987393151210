<template>
  <!-- kb-form-fields -->
  <div  class="kb-form-fields">
    <div :class="`kb-form-${isMobile ? 'item' : 'row'}`">
      <div class="kb-form-column kb-form-column-title">
        <label class="kb-form-label">
          <span class="kb-form-label-text">{{ labelText }}</span>
        </label>
      </div>
      <slot name="inner"></slot>
    </div>
  </div>
  <!-- //kb-form-fields -->


</template>

<script>
import navigationUtils from "@/assets/js/navigationUtils";

export default {
  name: "AuthFormField",
  props: {
    userInfo: Object,
    labelText: String,


  },
  setup() {
    const isMobile = navigationUtils.any();

    return {
      isMobile
    };
  }
}
</script>